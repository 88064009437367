/**
 * Notifications.js - handles notifications for Citizen Inclued
 */
const Notifications = (function () {

    var notifications = {
        /**
         * Loads/polls notifications
         */
        load: function () {

            $.ajax({
                url: '/get-notifications',
                type: 'get',
            }).done(function (response) { //

                notifications.update(response);

            }).fail(function (e) {
                console.log(e);
            });

            setInterval(function () {
                $.ajax({
                    url: '/get-notifications',
                    type: 'get',
                }).done(function (response) { //

                    notifications.update(response);

                }).fail(function (e) {
                    console.log(e);
                });
            }, 10000);


        },
        /**
         * Updates notifications
         * 
         * @param {*} data
         * @property {*} currenntNotifications
         * @property {*} totalNotifications
         */
        update: function (data) {

            var counter = 0;
            var totalCounter = 0;

            for (var i = 0; i < data[0].length; i++) {
                totalCounter++;
                if (data[0][i].status === 'unread') {
                    counter++;
                }
            }

            var notificationsElement = document.getElementById('myds-notifications-icon');

             // Hides the notification badge if there are 0 notifications
             if(counter == 0){
                $(notificationsElement).hide();
            } else {
                $(notificationsElement).show();
            }


            if(notificationsElement) {
              // Get the count from the element
              var currentNotifications = parseInt(notificationsElement.innerHTML);
              // Update the count
              notificationsElement.innerHTML = counter;
              if ($('#notifications-table').length && counter > currentNotifications) {
                  notifications.addRow(data[0][totalCounter - 1]);
              }
            }
        },
        /**
         * Adds table data and sets the values from the post request to /get-notifications endpoint
         * 
         * @property {*} data
         * @property {*} table
         * @property {*} dataTest
         */
        addTableData: function () {

            $.ajax({
                url: '/get-notifications',
                type: 'get',
            }).done(function (response) { //

                var data = response;
                var table = document.getElementById('notifications-table');
                var dataTest = data[0];
                // loop notifications 
                for (var key in dataTest) {
                    var row = table.insertRow(1);


                    var counter = 0
                    var id = 0;
                    for (var v in dataTest[key]) {
                        row.setAttribute('style', "cursor: -webkit-grab; cursor: pointer;");
                        row.setAttribute('data-id', data[0][key]['type_id']);
                        row.setAttribute('data-notification_id', data[0][key]['id']);

                        switch (v) {

                            case "status":

                                row.insertCell(counter);
                                row.cells[counter].innerHTML = data[0][key][v];
                                if (data[0][key][v] === 'unread') {
                                    row.setAttribute('class', "font-weight-bold");
                                } else {
                                    row.setAttribute('class', "font-weight-bold");
                                }
                                row.setAttribute('class', "notification-row");
                                counter++;

                                break;
                            case "type":
                                row.insertCell(counter);
                                row.cells[counter].innerHTML =  data[0][key][v][0].toUpperCase() + data[0][key][v].substring(1);
                                counter++;
                                break;
                            case "content":
                                row.insertCell(counter);
                                row.cells[counter].innerHTML = data[0][key][v];
                                row.cells[counter].id = v + '-notifications';
                                counter++;

                                break;
                            case "type_id":
                                row.setAttribute('data-url', '/' + data[0][key]['type'] + '/' + data[0][key]['type_id']);

                                break;
                            case "id":
                                $id = data[0][key][v];

                                break;
                        }
                    }
                }

                // Add the event handlers for the table click
                $(".notification-row").click(function () {
                    var url = this.dataset.url;
                    var id = this.dataset.id;
                    var notification_id = this.dataset.notification_id;
                    notifications.view(id, notification_id, url);
                });

            }).fail(function (e) {
                console.log(e);
            });


        },

        /** 
         * Adds rows to the notifications table
         * 
         * @param {*} data
         */
        addRow: function (data) {

            var table = document.getElementById('notifications-table');

            var row = table.insertRow(1);
            row.setAttribute('class', "font-weight-bold cursor");
            row.setAttribute('data-url', '/' + data.type + 's/' + data.type_id);
            row.setAttribute('data-id', data.type_id);
            row.setAttribute('data-notification_id', data.id);
            row.insertCell(0);

            row.cells[0].innerHTML = data.type;
            row.insertCell(1);

            row.cells[1].innerHTML = data.status;
            row.insertCell(2);
            row.cells[2].innerHTML = data.content;

            row.addEventListener('click', function(){
                console.log(this);
                var url = this.dataset.url;
                var id = this.dataset.id;
                var notification_id = this.dataset.notification_id;
                notifications.view(id, notification_id, url);
              });



        },
        /** 
        * Update the table view
        * 
        * @param {*} offer_id
        * @param {*} notification_id
        * 
        */
        view: function (offer_id, notification_id, url) {

            // Update the notification - set to read
            // redirect to page

            var csrf = Util.getCSRF();
            $.ajax({
                url: '/update-notifications',
                type: 'post',
                data: {
                    notification_id: notification_id
                },
                headers: { 'x-csrf-token': csrf }
            }).done(function (response) { //
                window.location = url;

            }).fail(function (e) {
                console.log(e);
            });
        }

    }

    return {
        notifications: notifications
    }

}());

window.onload = Notifications.notifications.load();

$(document).ready(function() {
    if ($('#myds-inclued-page-notifications').length) {
        Notifications.notifications.addTableData();
    }
});


window.Notifications = Notifications;